module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1035,"disableBgImageOnAlpha":true,"quality":70}},{"resolve":"gatsby-remark-embed-snippet","options":{}},{"resolve":"gatsby-remark-code-titles"},{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":"|>","aliases":{}}},{"resolve":"gatsby-remark-copy-linked-files","options":{}}],"remarkPlugins":[[null,{"external":{"table":"Table:","code":"Code:"},"internal":{"blockquote":"Source:","image":"Figure:"}}],null,null],"rehypePlugins":[null,null,[null,{"behavior":"prepend","content":{"type":"element","tagName":"span","properties":{"className":["link-icon"]},"children":[]}}]]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Personal home page with some of my thoughts","short_name":"JCM","start_url":"/","background_color":"#f7f7f7","theme_color":"#525dce","display":"standalone","icon":"assets/images/logo.png","cache_busting_mode":"none","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-11343647-9","head":false,"anonymize":true,"respectDNT":true,"defer":true},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
