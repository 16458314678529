// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-en-tsx": () => import("./../../../src/pages/404.en.tsx" /* webpackChunkName: "component---src-pages-404-en-tsx" */),
  "component---src-pages-404-pt-br-tsx": () => import("./../../../src/pages/404.pt-br.tsx" /* webpackChunkName: "component---src-pages-404-pt-br-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-root-tsx": () => import("./../../../src/pages/root.tsx" /* webpackChunkName: "component---src-pages-root-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/Blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-categories-tsx": () => import("./../../../src/templates/Categories.tsx" /* webpackChunkName: "component---src-templates-categories-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/Category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/Tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/Tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

